import Swal from "sweetalert2";
import { AuthService } from "./auth.service";

export class UserService {
    static async login(username: string, password: string) {
        if (username === '' && password === '') return false
        if (username === 'admin@example.com' && password === 'admin') {
            Swal.fire({
                title: "Please wait",
                text: "We are processing your request!",
                customClass: {
                    popup: "rounded-lg bg-white shadow-lg p-6 dark:bg-gray-800",
                    title: "text-lg font-semibold text-gray-900 dark:text-gray-100",
                    htmlContainer: "text-gray-600 dark:text-gray-300",
                    confirmButton: "bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded",
                    cancelButton: "bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium py-2 px-4 rounded",
                },
                didOpen: () => {
                    Swal.showLoading()
                }
            });

            // Simulate a delay of 1 second
            await new Promise(resolve => setTimeout(resolve, 1000))
            Swal.close()
            AuthService.saveAuth(username)
            return true
        } else {
            Swal.fire({
                icon: "error",
                title: "Login Failed",
                text: "Invalid username or password!",
                customClass: {
                    popup: "rounded-lg bg-white shadow-lg p-6 dark:bg-gray-800",
                    title: "text-lg font-semibold text-gray-900 dark:text-gray-100",
                    htmlContainer: "text-gray-600 dark:text-gray-300",
                    confirmButton: "bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded",
                    cancelButton: "bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium py-2 px-4 rounded",
                }
            })
            return false;
        }
    }
}