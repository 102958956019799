import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { AuthService } from "../services/auth.service"
import { MainLayout } from "./MainLayout"
import { FaBars } from "react-icons/fa"
import { Card } from "./ui/card"

export function Menu() {
    const navigate = useNavigate()

    useEffect(() => {
        if (!AuthService.hasAuth()) {
            navigate('/login', { replace: true })
            return
        }
    }, [])

    function logout() {
        AuthService.clearAuth()
        navigate('/login', { replace: true })
        return
    }

    return (
        <MainLayout>
            {/* Page Title */}
            <div className="flex items-center justify-left space-x-2">
                <FaBars className="text-3xl text-gray-800" />
                <h1 className="text-2xl font-bold text-gray-800">Menu</h1>
                <button type="button" onClick={logout}>LOGOUT NOW</button>
            </div>

            {/* User Cards */}
            <div className="space-y-2">
                <Card className="p-4 space-y-4 shadow-md rounded-lg border">
                    This is the Menu View
                </Card>
            </div>
        </MainLayout>
    )
}