import { Card } from "./ui/card"
import { FaBath, FaBed, FaHome } from "react-icons/fa"
import { Avatar, AvatarImage, AvatarFallback } from "./ui/avatar"
import { MdOutlineDashboard } from "react-icons/md"
import { PiForkKnifeBold } from "react-icons/pi"
import { useNavigate } from "react-router-dom"
import { AuthService } from "../services/auth.service"
import { useEffect } from "react"
import { DataService } from "../services/data.service"
import { MainLayout } from "./MainLayout"

export function Dashboard() {
    const navigate = useNavigate()

    useEffect(() => {
        if (!AuthService.hasAuth()) {
            navigate('/login', { replace: true })
            return
        }
    }, [])

    function logout() {
        AuthService.clearAuth()
        navigate('/login', { replace: true })
        return
    }

    return (
        <MainLayout>
            {/* Page Title */}
            <div className="flex items-center justify-left space-x-2">
                <MdOutlineDashboard className="text-3xl text-gray-800" />
                <h1 className="text-2xl font-bold text-gray-800">Dashboard</h1>
                <button type="button" onClick={logout}>LOGOUT NOW</button>
            </div>

            {/* User Cards */}
            <div className="space-y-2">
                {DataService.getAllPeople().map((user, index) => (
                    <Card key={index} className="p-4 space-y-4 shadow-md rounded-lg border">
                        <div className="flex items-center justify-between">
                            {/* Left: Avatar and Apartment */}
                            <div className="flex items-center space-x-4">
                                <Avatar className="w-12 h-12">
                                    <AvatarImage
                                        src={'https://ui-avatars.com/api/?name=' + user.name}
                                        alt={user.name}
                                    />
                                    <AvatarFallback>WN</AvatarFallback>
                                </Avatar>
                                <div>
                                    <h3 className="text-lg font-semibold">{user.name}</h3>
                                    <p className="text-sm text-gray-500">Apartment {user.apartment}</p>
                                </div>
                            </div>

                            {/* Right: Icons aligned in 2 rows and 2 columns */}
                            <div className="grid grid-cols-2 gap-2">
                                <FaHome className="text-green-500 text-2xl" />
                                <FaBath className="text-green-500 text-2xl" />
                                <PiForkKnifeBold className="text-red-500 text-2xl" />
                                <FaBed className="text-yellow-500 text-2xl" />
                            </div>
                        </div>

                        {/* Metrics Section */}
                        <div className="grid grid-cols-3 gap-4 text-center">
                            <div>
                                <p className="text-gray-800 font-medium">{user.temp}</p>
                                <p className="text-xs text-gray-500">Temp (°F)</p>
                            </div>
                            <div>
                                <p className="text-gray-800 font-medium">{user.smell}</p>
                                <p className="text-xs text-gray-500">Smell (VOC)</p>
                            </div>
                            <div>
                                <p className="text-gray-800 font-medium">{user.intensity}</p>
                                <p className="text-xs text-gray-500">CO₂ (ppm)</p>
                            </div>
                        </div>

                        {/* Time Section */}
                        <div className="grid grid-cols-3 gap-4 text-center">
                            <div>
                                <p className="text-gray-800 font-medium">{user.showerTime}</p>
                                <p className="text-xs text-gray-500">Since Shower</p>
                            </div>
                            <div>
                                <p className="text-red-500 font-medium">{user.eatingTime}</p>
                                <p className="text-xs text-gray-500">Since Eating</p>
                            </div>
                            <div>
                                <p className="text-gray-800 font-medium">{user.sleepTime}</p>
                                <p className="text-xs text-gray-500">Since Slept</p>
                            </div>
                        </div>
                    </Card>
                ))}
            </div>
        </MainLayout>
    )
}
