import { ReactNode, useEffect } from "react";
import { FaBars, FaCog } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { Julia } from "./Julia";
import './MainLayout.css'

export function MainLayout({ children }: { children: ReactNode }) {

    useEffect(() => {
        document.body.style.background = "#e9eefb";
    }, [])

    return (
        <div className="main-content">
            <div className="max-w-[1000px] mx-auto p-4 space-y-6">

                {children}

                {/* Footer */}
                <div className="fixed bottom-0 left-0 w-full main-content p-4 flex flex-col items-center shadow-lg">
                    {/* Julia component, placed above the menu */}
                    <div className="w-full max-w-[1000px]">
                        <div className="pb-4 pr-4 pl-4">
                            <Julia />
                        </div>
                    </div>

                    {/* Menu items */}
                    <div className="flex w-full max-w-[1000px] justify-around items-center">
                        <NavLink
                            className={({ isActive }) =>
                                `flex flex-col items-center border-t-4 nav-link pt-2 ${isActive ? 'border-blue-600 text-blue-600' : 'border-transparent'
                                }`
                            }
                            to="/dashboard"
                        >
                            <MdOutlineDashboard className="text-xl mb-1" />
                            <span className="text-sm">Dashboard</span>
                        </NavLink>

                        <NavLink
                            className={({ isActive }) =>
                                `flex flex-col items-center border-t-4 nav-link pt-2 ${isActive ? 'border-blue-600 text-blue-600' : 'border-transparent'
                                }`
                            }
                            to="/menu"
                        >
                            <FaBars className="text-xl" />
                            <span className="text-sm">Menu</span>
                        </NavLink>

                        <NavLink
                            className={({ isActive }) =>
                                `flex flex-col items-center border-t-4 nav-link pt-2 ${isActive ? 'border-blue-600 text-blue-600' : 'border-transparent'
                                }`
                            }
                            to="/settings"
                        >
                            <FaCog className="text-xl" />
                            <span className="text-sm">Settings</span>
                        </NavLink>
                    </div>
                </div>

                {/* Spacer to avoid footer overlap */}
                <div className="h-40"></div>
            </div>
        </div>
    );
}