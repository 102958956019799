export class AuthService {
    static saveAuth(email: string) {
        localStorage.setItem('auth', JSON.stringify({ email: email }))
    }

    static hasAuth() {
        return localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth')!).email != null
    }

    static clearAuth() {
        localStorage.setItem('auth', JSON.stringify({ email: null }))
    }

}