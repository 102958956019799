import { useState, useEffect, useRef } from 'react';
import { Card } from './ui/card';
import { Avatar, AvatarImage, AvatarFallback } from './ui/avatar'; // Import Avatar components from shadcn
import './Julia.css';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

export function Julia() {
    const [text, setText] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [isTalking, setIsTalking] = useState(false);
    const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
    const [voicesLoaded, setVoicesLoaded] = useState(false); // Track if voices are loaded
    const silenceTimer = useRef<NodeJS.Timeout | null>(null);
    const recognitionRef = useRef<SpeechRecognition | null>(null);
    const voicesLoadedRef = useRef(false);

    const checkMicrophonePermissions = async () => {
        try {
            //@ts-ignore
            const permission = await navigator.permissions.query({ name: 'microphone' });
            if (permission.state === 'denied') {
                alert('Microphone access is denied. Please enable it in your browser settings.');
            }
            console.log('Microphone permission:', permission.state);
        } catch (error) {
            console.error('Error checking microphone permissions:', error);
        }
    };
    
    useEffect(() => {
        checkMicrophonePermissions();
    }, []);

    // Function to find and return the female voice
    const getFemaleVoice = () => {
        return (
            voices.find(voice => voice.lang.startsWith('en') && voice.name.toLowerCase().includes('female')) ||
            voices.find(voice => voice.lang.startsWith('en')) // Fallback to any English voice
        );
    };

    useEffect(() => {
        const synth = window.speechSynthesis;

        const loadVoices = () => {
            const allVoices = synth.getVoices();
            setVoices(allVoices);
            voicesLoadedRef.current = true;
            setVoicesLoaded(true); // Mark voices as loaded
        };

        if (synth.onvoiceschanged !== undefined) {
            synth.onvoiceschanged = loadVoices;
        }

        // Safeguard to ensure voices are loaded even if `onvoiceschanged` doesn't trigger immediately
        setTimeout(() => {
            if (!voicesLoadedRef.current) {
                loadVoices();
            }
        }, 2000); // Adjust this timeout as necessary (e.g., 2 seconds)

    }, []);

    useEffect(() => {
        if (SpeechRecognition && voicesLoaded) { // Only initialize once voices are loaded
            recognitionRef.current = new SpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;
            recognitionRef.current.lang = 'en-US';

            recognitionRef.current.onresult = (event: SpeechRecognitionEvent) => {
                let interimTranscript = '';
                for (let i = event.resultIndex; i < event.results.length; ++i) {
                    if (event.results[i].isFinal) {
                        const transcript = event.results[i][0].transcript;
                        setText(transcript);
                        playText(transcript); // Speak the recognized text
                    } else {
                        interimTranscript += event.results[i][0].transcript;
                    }
                }
                if (interimTranscript) {
                    setText(interimTranscript);
                    resetSilenceTimer();
                }
            };

            recognitionRef.current.onstart = () => setIsListening(true);
            recognitionRef.current.onend = () => {
                if (!isTalking) {
                    setIsListening(false);
                }
            };
        } else if (!voicesLoaded) {
            console.log('Waiting for voices to load...');
        }

        return () => {
            if (recognitionRef.current) {
                recognitionRef.current.stop();
            }
        };
    }, [voicesLoaded, isTalking]);

    const startListening = () => {
        if (recognitionRef.current && !isListening && !isTalking) {
            recognitionRef.current.start();
            resetSilenceTimer();
        } else {
            console.log('SpeechRecognition already started or currently talking.');
        }
    };
    const stopListening = () => {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
        }
        if (silenceTimer.current) {
            clearTimeout(silenceTimer.current);
        }
        setIsListening(false);
    };

    const resetSilenceTimer = () => {
        if (silenceTimer.current) {
            clearTimeout(silenceTimer.current);
        }
        silenceTimer.current = setTimeout(() => {
            stopListening();
        }, 5000);
    };

    const playText = (message: string) => {
        if (!voicesLoaded) return; // Ensure voices are loaded before attempting to speak

        const synth = window.speechSynthesis;
        const utterThis = new SpeechSynthesisUtterance(message);

        const voice = getFemaleVoice() || synth.getVoices()[0]; // Use the first available voice if no female voice is found

        if (voice) {
            utterThis.voice = voice;
        } else {
            console.warn('No voice found, using the default system voice.');
        }

        utterThis.onstart = () => {
            setIsTalking(true);
            setIsListening(false); // Ensure listening stops when speaking
            stopListening(); // Stop listening when Julia starts talking
        };

        utterThis.onend = () => {
            setIsTalking(false);
            setIsListening(false); // Reset after talking
        };

        synth.cancel(); // Cancel any existing speech synthesis before starting
        synth.speak(utterThis);
    };

    return (
        <Card className="p-4 space-y-4 shadow-md rounded-lg border" onClick={isListening ? stopListening : startListening}>
            <div className="flex items-center justify-center gap-5">
                <div>
                    <h2 className="text-lg font-semibold">Julia</h2>
                    <p className="text-gray-500">
                        {isListening ? 'Listening...' : isTalking ? 'Speaking...' : !voicesLoaded ? 'Loading voices...' : 'Click to speak'}
                    </p>
                </div>
                <Avatar className="w-12 h-12">
                    <AvatarImage 
                        src={isListening ? "/assets/listening.webp" : "/assets/listening-static.webp"} 
                        alt="Julia icon" 
                    />
                    <AvatarFallback>J</AvatarFallback>
                </Avatar>
            </div>
            {text && (
                <p className="text-xs text-gray-500">
                    {text}
                </p>
            )}
        </Card>
    );
}
