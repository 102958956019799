import { useEffect, useState } from "react";
import { Label } from "@radix-ui/react-label";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card";
import { Input } from "./ui/input";
import './LoginForm.css';
import { UserService } from "../services/user.service";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../services/auth.service";

export function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.background = "white";
    if (AuthService.hasAuth()) {
      navigate('/dashboard', { replace: true })
      return
    }
  }, [])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (await UserService.login(email, password)) {
      navigate("/dashboard", { replace: true })
    }
  }

  return (
    <div className="login-container">
      <Card className="login-card">
        <div className="login-image">
          <img src="/assets/login-backgroud.png" alt="Login Background" />
        </div>
        <div className="card-white">
          <CardHeader>
            <CardTitle className="card-title">WellNuo</CardTitle>
            <CardDescription className="card-desc">So you know they are well</CardDescription>
          </CardHeader>
          <CardContent>
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <Button type="submit" className="login-button bg-blue-600">
                Log In
              </Button>
            </form>
          </CardContent>
        </div>
      </Card>
    </div>
  );
}
