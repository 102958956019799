import './App.css';
import { Dashboard } from './components/Dashboard';
import { LoginForm } from './components/LoginForm';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Menu } from './components/Menu';
import { Settings } from './components/Settings';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/settings" element={<Settings />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
