export class DataService {
    static getAllPeople() {
        return [
            {
                name: "John Smith",
                avatar: "/assets/grandpa.jpeg",
                apartment: "#90",
                temp: "68 F",
                smell: "Coffee",
                intensity: "56",
                showerTime: "22H 34m",
                eatingTime: "5H 11m",
                sleepTime: "7H 45m",
            },
            {
                name: "Beth Zimmerman",
                avatar: "/assets/grandma.webp",
                apartment: "#128",
                temp: "71 F",
                smell: "Coffee",
                intensity: "32",
                showerTime: "20H 34m",
                eatingTime: "7H 11m",
                sleepTime: "8H 45m",
            },
            {
                name: "Paul Simpson",
                apartment: "#68",
                avatar: "/assets/grandpa.jpeg",
                temp: "64 F",
                smell: "Coffee",
                intensity: "52",
                showerTime: "22H 34m",
                eatingTime: "5H 11m",
                sleepTime: "7H 45m",
            },
            {
                name: "Stephanie Deare",
                avatar: "/assets/grandma.webp",
                apartment: "#68",
                temp: "72 F",
                smell: "Coffee",
                intensity: "67",
                showerTime: "14H 12m",
                eatingTime: "7H 38m",
                sleepTime: "5H 46m",
            }
        ]
    }
}